import axios from "axios";
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";

const Registeruser = () => {
  const [username, setUsername] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("admin");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [errordel, setErrordel] = useState("");
  const [erroredit, setErroredit] = useState("");
  const [data, setData] = useState([]);
  const [ErrorUserMsg, setErrorUserMsgMsg] = useState(null);
  const [messagedel, setMessagedel] = useState(true);
  const [messageedit, setMessageedit] = useState(true);
  const [oldusername, setOldUsername] = useState("");
  const [deleteconfirm, setDeleteConfirm] = useState(false);
  const [editconfirm, setEditConfirm] = useState(false);
  const [editsectionmsg, setEditSectionMsg] = useState(false);
  const [registerbutton, setRegisterButton] = useState(false);
  const [backtoregister, setBackToRegister] = useState(false);
  const [updatedata, setUpdateData] = useState(false);
  const [registermsg, setRegisterMsg] = useState("");
  let url = process.env.REACT_APP_BASE_URL;
  let baseURL = process.env.REACT_APP_LEDGER_URL;

  useEffect(() => {
    setUser(localStorage.getItem("user"));
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMessagedel(false);
      setErrordel(false);
      setErrorUserMsgMsg(false);
      setMessageedit(false);
      setRegisterMsg(false);
      setErroredit(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [ErrorUserMsg, errordel, erroredit, messageedit, registermsg, messagedel]);

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleTypeChange = (e) => setType(e.target.value);
  const handlebacktoregister = (e) => setBackToRegister(true);

  useEffect(() => {
    if (backtoregister === true) {
      window.location.reload();
    }
  }, [backtoregister]);

  const fetchUserData = () => {
    let userlist = { token: "test", username: user };
    axios
      .post(`${url}/userlists`, userlist)
      .then((response) => {
        setData(response.data);
        setUpdateData(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchUserData();
  }, [deleteconfirm, updatedata, registermsg, messageedit, messagedel]);

  const toggleVisibility = (e) => {
    e.preventDefault();
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      username,
      password,
      type,
      restaurantURL: url,
      baseURL: baseURL,
    };

    const usereditData = {
      token: "test",
      username: oldusername,
      new_username: username,
      new_password: password,
      new_type: type,
      restaurantURL: url,
      baseURL: baseURL,
    };

    if (editconfirm === false) {
      try {
        const response = await axios.post(`${url}/register`, userData);
        console.log(response.data);
        setData([...data, response.data]);
        setUpdateData(true);
        setRegisterMsg("User Registered Successfully");
      } catch (error) {
        if (error.response && error.response.data) {
          setErrorUserMsgMsg(
            error.response.data.message || "Username already exists"
          );
        } else {
          setErrorUserMsgMsg("An error occurred while registering the user.");
        }
      }
    } else {
      try {
        const response = await axios.post(`${url}/edituser`, usereditData);
        console.log(response.data);
        setMessageedit("User edited successfully!");
        setUpdateData(true);
      } catch (error) {
        if (error.response && error.response.data) {
          setErroredit(error.response.data.message || "Failed to edit user");
        } else {
          setErroredit("An error occurred while editing the user.");
        }
      }
    }
  };

  const handleAlert = async (username) => {
    let postdata = { token: "test", username: username };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        setDeleteConfirm(true);
        try {
          axios.post(`${url}/deleteuser`, postdata);
          setMessagedel("User deleted successfully!");
          setErrordel("");
        } catch (error) {
          if (error.response && error.response.data) {
            setErrordel(error.response.data.message || "Failed to delete user");
          } else {
            setErrordel("An error occurred while deleting the user.");
          }
        }
      }
    });
  };

  const handleEditAlert = async (username, password, type) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to edit data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, edit data!",
    }).then((result) => {
      if (result.isConfirmed) {
        setEditConfirm(true);
        setEditSectionMsg(true);
        setRegisterButton(true);
        setUsername(username);
        setPassword(password);
        setType(type);
      }
    });
  };

  return (
    <>
      <Navbar />
      <div className="container W-1/2 flex justify-between">
        <div className="w-1/2">
          <form
            autoComplete="off"
            onSubmit={(e) => {
              handleSubmit(e);
              setUpdateData(true);
            }}>
            {editsectionmsg && (
              <label className="font-bold pt-3">Edit Existing User</label>
            )}
            {!editsectionmsg && (
              <label className="font-bold pt-3">Register New User</label>
            )}
            <div>
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                name="username"
                className="border p-2 ml-2 mt-5"
                autoComplete="off"
                value={username}
                onChange={handleUsernameChange}
                required
              />
              {ErrorUserMsg && (
                <p style={{ color: "red", marginTop: "5px" }}>{ErrorUserMsg}</p>
              )}
            </div>
            <div>
              <label htmlFor="text">Password:</label>
              <input
                type={isPasswordVisible ? "text" : "password"}
                id="text"
                name="password"
                className="border p-2 ml-3 mt-2"
                autoComplete="off"
                value={password}
                onChange={handlePasswordChange}
                required
              />
              <button className="ml-3" onClick={toggleVisibility}>
                {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            <div>
              <label htmlFor="type">User Type:</label>
              <select
                id="type"
                name="type"
                className="border px-16 ml-3 mt-2"
                value={type}
                onChange={handleTypeChange}>
                <option value="admin">Admin</option>
                <option value="agent">Agent</option>
              </select>
            </div>
            <button
              type="submit"
              className="border rounded-3xl px-10 mt-5 py-2 bg-blue-100">
              Submit
            </button>
            {registerbutton && (
              <button
                type="button"
                className="border rounded-3xl px-10 mt-5 py-2 bg-green-100 ml-3"
                onClick={handlebacktoregister}>
                Register
              </button>
            )}
            {registermsg && <p style={{ color: "green" }}>{registermsg}</p>}
          </form>
        </div>

        <div className="w-1/2">
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto border-collapse mt-2">
              <thead>
                <tr>
                  <th className="border px-4 py-2 text-left">Username</th>
                  <th className="border px-4 py-2 text-left">Password</th>
                  <th className="border px-4 py-2 text-left">Type</th>
                  <th className="border px-4 py-2 text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">{item.username}</td>
                    <td className="border px-4 py-2">{item.password}</td>
                    <td className="border px-4 py-2">{item.type}</td>
                    <td className="border px-4 py-2">
                      <div className="flex flex-col justify-between">
                        <button
                          className="p-1 rounded-3xl bg-red-400 text-white"
                          onClick={() => {
                            handleAlert(item.username);
                          }}>
                          Delete
                        </button>
                        <button
                          className="p-1 rounded-3xl bg-blue-400 mt-1 text-white"
                          onClick={() => {
                            setOldUsername(item.username);
                            handleEditAlert(
                              item.username,
                              item.password,
                              item.type
                            );
                          }}>
                          Edit
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
                {errordel && <p style={{ color: "red" }}>{errordel}</p>}
                {messagedel && <p style={{ color: "green" }}>{messagedel}</p>}
                {erroredit && <p style={{ color: "red" }}>{erroredit}</p>}
                {messageedit && <p style={{ color: "green" }}>{messageedit}</p>}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registeruser;
