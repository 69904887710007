import React, { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import "../../../scss/sticky.scss";
const BeverageTable = ({ beverage, beverageGroup, FoodBeverageSum }) => {
  const tableRef = useRef(null);
  return (
    <div className="flex flex-col">
      <div className="table-responsive-beverage">
        <div className="table-responsive-food-header">
          <div className="bg-heading-food">
            <h4 className="beverage-heading">Beverage</h4>
          </div>
          <DownloadTableExcel
            filename="beverages_table"
            sheet="beverages"
            currentTableRef={tableRef.current}>
            <button className="export"> Export </button>
          </DownloadTableExcel>
        </div>
        <div className="table-responsive" ref={tableRef}>
          <table className="table-beverage">
            <tr className="sticky-header">
              <th>Group</th>
              <th>Item Name </th>
              <th>Item Rate (Rs)</th>
              <th>Quantity</th>
              <th>Total(Rs)</th>
            </tr>
            {!beverage?.error &&
              beverage.map((item, index) => (
                <tr key={index}>
                  <td>{item.Description}</td>
                  <td>{item.itemName}</td>
                  <td>{item.itemrate}</td>
                  <td>{item.quantity}</td>
                  <td>{item.total}</td>
                </tr>
              ))}
          </table>
        </div>
        {FoodBeverageSum.map((item, index) => (
          <div className="flex justify-end" key={index}>
            {" "}
            <p className="font-bold text-xs p-2">
              Quantity: {item.beveragequantity}
            </p>
            <p className="font-bold text-xs p-1 pt-0 mr-2">
              Total: {item.beveragetotal}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BeverageTable;
