import React, { useState, useEffect } from "react";
import Navbar from "./../Navbar/index";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Filter from "../Filter";
import TotalSale from "./TotalSale";
import TimeStats from "./TimeStats";
import TimeSales from "./TimeSales";
import TimelineTable from "./TimelineTableSummary";
import Footer from "../Footer";
import "../../scss/timeline.scss";
import FoodTable from "./FoodTable";
import Topfoodtable from "./Topfoodtable";
import BeverageTable from "./BeverageTable";
import Topbeveragetable from "./Topbeveragetable";

const Timeline = () => {
  let url = process.env.REACT_APP_BASE_URL;
  const [token, setToken] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [totalSaleSummary, setTotalSaleSummary] = useState({});
  const [timeStats, setTimeStats] = useState({});
  const [elevenThree, setelevenThree] = useState({});
  const [sixTen, setsixTen] = useState({});
  const [threeSix, setThreeSix] = useState({});
  const [tableSumamry, setTableSumamry] = useState([]);
  const [showTimeline, setShowTimeline] = useState(false);
  const [food113, setFood113] = useState([]);
  const [beverage113, setBeverage113] = useState([]);
  const [foodsum113, setfoodsum113] = useState("");
  const [beveragesum113, setbeveragesum113] = useState("");
  const [totalfoodquantity113, settotalfoodquantity113] = useState();
  const [totalbeveragequantity113, settotalbeveragequantity113] = useState();
  const [food36, setFood36] = useState([]);
  const [beverage36, setBeverage36] = useState([]);
  const [foodsum36, setfoodsum36] = useState("");
  const [beveragesum36, setbeveragesum36] = useState("");
  const [totalfoodquantity36, settotalfoodquantity36] = useState();
  const [totalbeveragequantity36, settotalbeveragequantity36] = useState();
  const [food610, setFood610] = useState([]);
  const [beverage610, setBeverage610] = useState([]);
  const [foodsum610, setfoodsum610] = useState("");
  const [beveragesum610, setbeveragesum610] = useState("");
  const [totalfoodquantity610, settotalfoodquantity610] = useState();
  const [totalbeveragequantity610, settotalbeveragequantity610] = useState();
  const [topfood, setTopFood] = useState([]);
  const [topbeverage, setTopBeverage] = useState([]);
  const [topfoodrev, setTopFoodRev] = useState([]);
  const [topbeveragerev, setTopBeverageRev] = useState([]);

  let start = startDate.toISOString().slice(0, 10);
  let end = endDate.toISOString().slice(0, 10);

  let navigate = useNavigate();

  useEffect(() => {
    let tokenCheck = localStorage.getItem("token");
    if (!tokenCheck) {
      navigate("/");
    } else {
      setToken(localStorage.getItem("token"));
    }
  }, []);

  const viewTimeline = () => {
    axios
      .post(`${url}/orderhistory`, {
        outlet: `${selectedOutlet}`,
        dateStart: start,
        dateEnd: end,
        token: `${token}`,
      })
      .then((response) => {
        console.log(response.data.Time_sales.six_ten);
        console.log(response.data);
        setShowTimeline(true);
        console.log(response.data.Timestats);
        setTimeStats(response.data.Timestats);
        setTotalSaleSummary(response.data.TotalSalesSummary);
        setelevenThree(response.data.Time_sales.eleven_three);
        setsixTen(response.data.Time_sales.six_ten);
        setThreeSix(response.data.Time_sales.three_six);
        setTableSumamry(response.data.table_summary);
        setFood113(response.data.Time_sales.eleven_three.food_sales);
        setBeverage113(response.data.Time_sales.eleven_three.beverage_sales);
        setfoodsum113(response.data.Time_sales.eleven_three.total_food_sales);
        setbeveragesum113(
          response.data.Time_sales.eleven_three.total_beverage_sales
        );
        settotalbeveragequantity113(
          response.data.Time_sales.eleven_three.beveragesales_count
        );
        settotalfoodquantity113(
          response.data.Time_sales.eleven_three.foodsales_count
        );

        setFood36(response.data.Time_sales.three_six.food_sales);
        setBeverage36(response.data.Time_sales.three_six.beverage_sales);
        setfoodsum36(response.data.Time_sales.three_six.total_food_sales);
        setbeveragesum36(
          response.data.Time_sales.three_six.total_beverage_sales
        );
        settotalbeveragequantity36(
          response.data.Time_sales.three_six.beveragesales_count
        );
        settotalfoodquantity36(
          response.data.Time_sales.three_six.foodsales_count
        );
        setFood610(response.data.Time_sales.six_ten.food_sales);
        setBeverage610(response.data.Time_sales.six_ten.beverage_sales);
        setfoodsum610(response.data.Time_sales.six_ten.total_food_sales);
        setbeveragesum610(
          response.data.Time_sales.six_ten.total_beverage_sales
        );
        settotalbeveragequantity610(
          response.data.Time_sales.six_ten.beveragesales_count
        );
        settotalfoodquantity610(
          response.data.Time_sales.six_ten.foodsales_count
        );
        setTopFood(response.data.Top_tenselling_food_by_qty);
        setTopBeverage(response.data.Top_tenselling_beverage_by_qty);
        setTopFoodRev(response.data.Top_tenselling_food_by_revenue);
        setTopBeverageRev(response.data.Top_tenselling_beverage_by_revenue);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <Navbar />
      <div className="filter-timeline">
        <Filter
          startDate={startDate}
          setStartDate={setStartDate}
          selectedOutlet={selectedOutlet}
          setSelectedOutlet={setSelectedOutlet}
          endDate={endDate}
          setEndDate={setEndDate}
          view={viewTimeline}
          btn={"Timeline"}
        />
      </div>
      <div className="bg-timeline">
        {showTimeline && (
          <div className="timeline">
            <div className="left-timeline">
              <h4>{selectedOutlet}</h4>
              <div className="hl-line"></div>
              <div className="md-flex">
                <TotalSale totalSaleSummary={totalSaleSummary} />
                <div className="hl-line"></div>
                <TimeStats timeStats={timeStats} />
              </div>
              <div className="hl-line"></div>
              <TimeSales
                elevenThree={elevenThree}
                sixTen={sixTen}
                threeSix={threeSix}
              />
            </div>
            <div className="right-timeline-table">
              <TimelineTable tableSumamry={tableSumamry} />
              <div className="time-stamp ml-8 font-bold">
                <label className="morning">11AM -3PM</label>
              </div>
              <div className="food-beverage-table-width">
                <div className="food-beverage-table">
                  <FoodTable
                    food={food113}
                    foodsum={foodsum113}
                    totalfoodquantity={totalfoodquantity113}
                  />
                  <BeverageTable
                    beverage={beverage113}
                    beveragesum={beveragesum113}
                    totalbeveragequantity={totalbeveragequantity113}
                  />
                </div>
              </div>
              <div className="time-stamp ml-8 font-bold">
                <label className="evening">3PM -6PM</label>
              </div>
              <div className="food-beverage-table-width">
                <div className="food-beverage-table ">
                  <FoodTable
                    food={food36}
                    foodsum={foodsum36}
                    totalfoodquantity={totalfoodquantity36}
                  />
                  <BeverageTable
                    beverage={beverage36}
                    beveragesum={beveragesum36}
                    totalbeveragequantity={totalbeveragequantity36}
                  />
                </div>
              </div>
              <div className="time-stamp ml-8 font-bold">
                <label className="late">6PM -10PM</label>
              </div>
              <div className="food-beverage-table-width">
                <div className="food-beverage-table">
                  <FoodTable
                    food={food610}
                    foodsum={foodsum610}
                    totalfoodquantity={totalfoodquantity610}
                  />
                  <BeverageTable
                    beverage={beverage610}
                    beveragesum={beveragesum610}
                    totalbeveragequantity={totalbeveragequantity610}
                  />
                </div>
              </div>
              <div className="time-stamp ml-8 font-bold">
                <label className="late">Top 10 Selling Items by Quantity</label>
              </div>
              <div className="food-beverage-table-width">
                <div className="food-beverage-table">
                  <Topfoodtable food={topfood} />
                  <Topbeveragetable beverage={topbeverage} />
                </div>
              </div>
              <div className="time-stamp ml-8 font-bold">
                <label className="late">Top 10 Selling Items by Revenue</label>
              </div>
              <div className="food-beverage-table-width">
                <div className="food-beverage-table">
                  <Topfoodtable food={topfoodrev} />
                  <Topbeveragetable beverage={topbeveragerev} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Timeline;
