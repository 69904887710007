import React, { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import "../../scss/sticky.scss";

const FoodTable = ({ food, foodsum, totalfoodquantity }) => {
  const tableRef = useRef(null);

  return (
    <div className="flex flex-col">
      <div className="table-responsive-food">
        <div className="table-responsive-food-header">
          <div className="bg-heading-food">
            <h4 className="food-heading">Food</h4>
          </div>
          <DownloadTableExcel
            filename="foods_table"
            sheet="foods"
            currentTableRef={tableRef.current}>
            <button className="export"> Export </button>
          </DownloadTableExcel>
        </div>
        <div className="table-responsive" ref={tableRef}>
          <table className="table-food">
            <thead className="table-header-bg">
              <tr className="sticky-header">
                <th>Group</th>
                <th>Item Name </th>
                <th>Item Rate (Rs)</th>
                <th>Quantity</th>
                <th>Total(Rs)</th>
              </tr>
            </thead>
            {!food.error &&
              food.map((item) => (
                <tr>
                  <td>{item.Description}</td>
                  <td>{item.ItemName}</td>
                  <td>{item.itemRate}</td>
                  <td>{item.count}</td>
                  <td>{item.Total}</td>
                </tr>
              ))}
          </table>
        </div>
        <div className="flex justify-end mt-auto ">
          <p className="font-bold text-xs p-2">Quantity: {totalfoodquantity}</p>
          <p className="font-bold text-xs p-2 pt-0 mr-2">Total: {foodsum}</p>
        </div>
      </div>
    </div>
  );
};

export default FoodTable;
