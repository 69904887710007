import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const Barchart = ({ dineinTabs, show }) => {
  const [outlet, setOutlet] = useState([]);
  const [sales, setSales] = useState([]);
  const [bevsales, setBevSales] = useState([]);
  const [foodsales, setFoodSales] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (show && dineinTabs && dineinTabs.outletwise_sales) {
      const newoutlet = [];
      const newsales = [];
      const newbevsales = [];
      const newfoodsales = [];
      dineinTabs.outletwise_sales.forEach((item) => {
        newoutlet.push(item.Outlet);
        newsales.push(item.TotalSales);
        newbevsales.push(item.beverageSale);
        newfoodsales.push(item.foodSale);
      });

      setOutlet(newoutlet);
      setSales(newsales);
      setFoodSales(newfoodsales);
      setBevSales(newbevsales);
    }
  }, [show, dineinTabs]);

  return (
    <div className="container w-3/4">
      <Chart
        className="table-responsive-bill"
        type="bar"
        width={windowWidth * 0.7}
        height={500}
        series={[
          {
            name: "Total Sales",
            data: sales,
            type: "bar",
          },
          {
            name: "Beverage Sales",
            data: bevsales,
            type: "line",
            stroke: { width: 1 },
          },
          {
            name: "Food Sales",
            data: foodsales,
            type: "line",
            stroke: { width: 2 },
          },
        ]}
        options={{
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "50%",
            },
          },
          title: {
            text: "Total Sales by Outlet",
            style: { fontSize: 20, color: "#000" },
          },
          colors: ["#059142", "#FF5733", "#FFC300"],
          theme: { mode: "light" },
          barWidth: "10%",
          xaxis: {
            tickPlacement: "on",
            categories: outlet,
            title: {
              text: "Outlet",
              style: { color: "#000000", fontSize: 20 },
            },
          },
          yaxis: [
            {
              labels: {
                formatter: (sales) =>
                  `Nrs. ${new Intl.NumberFormat().format(sales)}`,
                style: { fontSize: "15px", colors: ["#059142"] },
              },
              title: {
                text: "Total Sales",
                style: { color: "#000000", fontSize: 15 },
              },
            },
            {
              opposite: true,
              labels: {
                formatter: (sales) =>
                  `Nrs. ${new Intl.NumberFormat().format(sales)}`,
                style: {
                  fontSize: "15px",
                  colors: ["#FF5733", "#FFC300"],
                },
              },
              title: {
                text: "Sales (Bev + Food)",
                style: { color: "#000000", fontSize: 15 },
              },
            },
          ],
          legend: {
            show: true,
            position: "right",
          },
          dataLabels: {
            formatter: (sales) =>
              `Nrs. ${new Intl.NumberFormat().format(sales)}`,
            style: {
              colors: ["#171717", "#FF0000", "#FFFF00"],
              fontSize: 12,
            },
          },
          stroke: {
            width: [0, 2, 3],
          },
          grid: {
            show: true,
          },
        }}
      />
    </div>
  );
};

export default Barchart;
