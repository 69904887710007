import React from "react";
import Registeruser from "./Registeruser";

const User = () => {
  return (
    <>
      <Registeruser />
    </>
  );
};

export default User;
